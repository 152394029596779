import AuthService from '@/services/auth.service'

export const authMixin = {
  computed: {
    currentUser () {
      return AuthService.getCurrentUser()
    },
    authInProgress () {
      return this.$store.state.auth.status.inProgress
    }
  },
  methods: {
    hasAnyRole (roles) {
      return AuthService.hasAnyRole(roles)
    }
  }
}
