<template>
  <div class="text-center container-fluid">

      <h1>API Service</h1>

      <img alt="Friedrich-Alexander Universität Erlangen-Nürnberg" src="../assets/icons/logo/FAU_Wortmarke_RGB_blue.svg" />

      <b-jumbotron>
          <p class="text-left" v-html="$t('comp.start.api.text')">
          </p>

          <hr class="my-4">

          <blockquote class="blockquote m-5 text-right">
              <p class="mb-0">{{ $t('comp.start.quote.text') }}</p>
<!--              <footer class="blockquote-footer"><cite title="Source Title">{{ $t('comp.start.quote.source.title') }}</cite></footer>-->
          </blockquote>

<!--          <router-link class="btn btn-primary" to="/doc">  {{ $t('doc.info.text.label') }}</router-link>-->
<!--          <router-link class="btn btn-primary ml-3" to="/root"> {{ $t('root.frontend.dib.label') }}</router-link>-->
      </b-jumbotron>

<!--    <div v-if="!currentUser" class="row my-4">-->
<!--      <div class="col text-center">-->
<!--        <b-button @click="login" size="lg" class="text-center">-->
<!--          <b-icon-box-arrow-in-right/> {{ $t('comp.start.signin.label') }}-->
<!--        </b-button>-->
<!--        <div v-if="redirectAfterLogin" class="text-secondary small"><b-icon-x-circle @click="clearRedirect" class="cursorPointer" :title="$t('comp.home.remove.redirect.title')"/> {{ redirectAfterLogin }}</div>-->
<!--      </div>-->
<!--    </div>-->

  </div>
</template>

<script>
import { authMixin } from '@/mixins/auth.mixin'
import { i18nMixin } from '@/mixins/i18n.mixin'
import AuthService from '@/services/auth.service'

export default {
  name: 'StartView',
  i18nCompName: 'start',
  i18n: {
    messages: {}
  },
  mixins: [authMixin, i18nMixin],
  mounted: function () {
    this.docTitle()
  },
  computed: {
    currentLang () {
      return this.$store.state.prefs.lang
    },
    redirectAfterLogin () {
      return this.$store.state.auth.redirectAfterLogin
    }
  },
  watch: {
    currentLang: function (n, o) {
      this.docTitle()
    },
    messagesAvailable: function (n, o) {
      this.docTitle()
    }
  },
  methods: {
    docTitle () {
      document.title = this.$t('comp.start.title')
    },
    clearRedirect () {
      this.$store.commit('auth/clearRedirectAfterLogin')
    },
    login () {
      // this.$store.commit('auth/triggerLogin', true)
      // this.$store.state.auth.redirectAfterLogin = this.$router.currentRoute.fullPath
      AuthService.loginRedirect()
    }
  }
}
</script>

<style>
</style>
