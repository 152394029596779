<template>
  <div role="navigation">
    <b-navbar toggleable="lg" fixed="top" :sticky="true" class="header">
      <b-navbar-brand class="sr-only sr-only-focusable mr-2" href="#main-content">{{ $t('skip.to.main.content.label')}}</b-navbar-brand>
      <b-navbar-brand :href="homeurl" :title="$t('app.description')">
        <img src="../assets/icons/logo/idm_blue.svg" height="35px" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
<!--          <b-nav-item to="/dir" v-if="showDir" active-class="text-link">{{ $t('header.dir.label') }}</b-nav-item>-->
          <b-nav-item to="/doc" v-if="showDoc" active-class="text-link">{{ $t('header.doc.label') }}</b-nav-item>
          <b-nav-item to="/user" v-if="showUser" active-class="text-link">{{ $t('header.user.label') }}</b-nav-item>
          <b-nav-item to="/root" v-if="showRootBoard" active-class="text-link">{{ $t('header.root.label') }}</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">

          <b-nav-item-dropdown right>
            <template v-slot:button-content>
              <img src="../assets/icons/logo/language.svg" alt="Language switcher" class="svg-lang-switcher"/>
              {{ langName($root.$i18n.locale) }}
            </template>
            <b-dropdown-item v-for="lang in langs" :key="lang" @click="changeLocale(lang)" :lang="lang">{{ langName(lang) }}</b-dropdown-item>
          </b-nav-item-dropdown>

          <TheSessionTimer/>

          <b-nav-item-dropdown v-if="currentUser" right>
            <template v-slot:button-content>
              <b-icon icon="person-fill" variant="dark"/>
            </template>
            <div style="min-width: 250px;">
              <div>
                {{ $t('springSecurity.login.username.label') }}: <em>{{ currentUser.username }}</em>
              </div>
              <b-button @click="logout" variant="secondary" class="w-100 mr-0" >
                <b-icon-box-arrow-right/> {{ $t('logout.button') }}
              </b-button>
            </div>
          </b-nav-item-dropdown>
          <b-nav-item v-else @click="login"><b-icon-box-arrow-in-right/> {{ $t('signin.label') }}</b-nav-item>
          <TheNodeWitcher v-if="showNodes"/>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-modal
      v-model="showLoginModal"
      size="md"
      hide-footer
    >
    </b-modal>
  </div>
</template>

<script>
import AuthService from '@/services/auth.service'
import { loadLanguageAsync } from '@/i18n'
import { authMixin } from '@/mixins/auth.mixin'
import { notificationsMixin } from '@/mixins/notifications.mixin'
import TheNodeWitcher from '@/components/root/TheNodeWitcher'
import TheSessionTimer from '@/components/common/TheSessionTimer'
import { i18nMixin } from '@/mixins/i18n.mixin'

const ADMIN_ROLE = process.env.VUE_APP_ADMIN_ROLE

export default {
  name: 'TheHeader',
  i18nCompName: 'header',
  i18n: {
    messages: {}
  },
  components: { TheNodeWitcher, TheSessionTimer },
  mixins: [authMixin, notificationsMixin, i18nMixin],
  data () {
    return {
      backenduiurl: process.env.VUE_APP_BACKEND_UI_URL,
      homeurl: process.env.VUE_APP_HOME_URL,
      showLoginModal: false,
      langs: ['en', 'de'],
      displayName: null,
      username: null,
      password: null,
      loggedIn: false
    }
  },
  computed: {
    langNames () {
      return {
        en: this.$t('lang.en.label'),
        de: this.$t('lang.de.label')
      }
    },
    showDir () {
      return true // this.hasAnyRole([ADMIN_ROLE])
    },
    showDoc () {
      return true // this.hasAnyRole([ADMIN_ROLE])
    },
    showUser () {
      return this.hasAnyRole([ADMIN_ROLE])
    },
    showNodes () {
      return this.hasAnyRole([ADMIN_ROLE])
    },
    showRootBoard () {
      return this.hasAnyRole([ADMIN_ROLE])
    },
    unauthorizedConfirmed () {
      return this.$store.state.auth.unauthorized.confirmed
    },
    triggerLogin () {
      return this.$store.state.auth.triggerLogin
    }
  },
  watch: {
    unauthorizedConfirmed (confirmed) {
      if (confirmed && this.currentUser.login === 'local' && this.currentUser.username) {
        this.username = this.currentUser.username
        this.showLoginModal = true
      }
    },
    triggerLogin (newValue) {
      if (newValue) {
        this.showLoginModal = true
        this.$store.commit('auth/triggerLogin', false)
      }
    }
  },
  methods: {
    langName (lang) {
      if (lang in this.langNames) {
        return this.langNames[lang]
      }
      return lang
    },
    changeLocale (lang) {
      this.$store.commit('prefs/lang', lang)
      loadLanguageAsync(lang)
    },
    ping () {
      const requestStartedAt = new Date().getTime()
      this.$store.dispatch('auth/ping').then(
        () => this.makeToast(this.$i18n.t('ping.result.text', { time: new Date().getTime() - requestStartedAt }), this.$i18n.t('result.success.title'), 'success')
      ).catch(
        error => this.makeToast(this.$i18n.t('error.text', { status: 'na', message: error.message, id: 'ping' }), this.$i18n.t('result.error.title'), 'danger')
      )
    },
    logout () {
      AuthService.logoutRedirect()
    },
    login () {
      this.$store.state.auth.redirectAfterLogin = this.$router.currentRoute.fullPath
      AuthService.loginRedirect()
    }
  }
}
</script>

<style scoped>

</style>
