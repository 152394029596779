<template>
  <b-nav-item-dropdown right>
    <template v-slot:button-content>
              <span :title="authStatus.serverNode + ' ' + $t('admins.only.hint')">
                <b-icon-server/> {{ currentNodeText }}
              </span>
    </template>
    <b-dropdown-item v-for="serverNode in authStatus.serverNodes" :key="serverNode" @click="changeNode(serverNode)">{{ serverNode }}</b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { cookiesMixin } from '@/mixins/cookies.mixin'
import { notificationsMixin } from '@/mixins/notifications.mixin'

export default {
  name: 'TheNodeWitcher',
  mixins: [cookiesMixin, notificationsMixin],
  computed: {
    authStatus () {
      return this.$store.state.auth.status
    },
    currentNodeText () {
      if (this.authStatus.serverNode) {
        if (this.authStatus.serverNode.length > 5) {
          return this.authStatus.serverNode.substr(0, 5) + '...'
        }
        return this.authStatus.serverNode
      }
      return ''
    }
  },
  methods: {
    changeNode (serverNode) {
      this.setNode(serverNode)
      const requestStartedAt = new Date().getTime()
      this.$store.dispatch('auth/ping').then(
        () => this.makeToast(this.$i18n.t('ping.result.text', { time: new Date().getTime() - requestStartedAt }), this.$i18n.t('result.success.title'), 'success')
      ).catch(
        error => this.makeToast(this.$i18n.t('error.text', { status: 'na', message: error.message, id: 'ping' }), this.$i18n.t('result.error.title'), 'danger')
      )
    }
  }
}
</script>

<style scoped>

</style>
