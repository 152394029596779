import { loadComponentLanguageAsync } from '@/i18n'

export const i18nMixin = {
  watch: {
    '$i18n.locale': function () {
      loadComponentLanguageAsync(this).finally()
    }
  },
  computed: {
    messagesAvailable () {
      if ('$i18n' in this) {
        return Object.keys(this.$i18n.getLocaleMessage(this.$i18n.locale)).length !== 0
      } else {
        return false
      }
    }
  },
  created () {
    loadComponentLanguageAsync(this).finally()
  }
}
