import Vue from 'vue'
import VueRouter from 'vue-router'
import StartView from '@/views/StartView'
import AuthService from '@/services/auth.service'
import store from '@/store'

Vue.use(VueRouter)

const ADMIN_ROLE = process.env.VUE_APP_ADMIN_ROLE

const routes = [
  {
    path: '/',
    name: 'start',
    component: StartView
  },
  {
    path: '/loggedout',
    name: 'loggedout',
    component: () => import(/* webpackChunkName: "loggedout" */ '../views/LoggedOut.vue')
  },
  {
    path: '/errorlogin',
    name: 'errorlogin',
    component: () => import(/* webpackChunkName: "errorlogin" */ '../views/ErrorLogin.vue')
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import(/* webpackChunkName: "unauthorized" */ '../views/UnauthorizedView.vue')
  },
  {
    path: '/dir/:tab?/:id?',
    name: 'dir',
    props: true,
    component: () => import(/* webpackChunkName: "root" */ '../views/DirView.vue'),
    meta: { authorize: [ADMIN_ROLE] }
  },
  {
    path: '/doc/:id?/:vers?',
    name: 'doc',
    component: () => import(/* webpackChunkName: "doc" */ '../views/DocView.vue')
  },
  {
    path: '/user/',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ '../views/UserView.vue'),
    meta: { authorize: [ADMIN_ROLE] }
  },
  {
    path: '/root/:tab?/:id?',
    name: 'root',
    props: true,
    component: () => import(/* webpackChunkName: "root" */ '../views/RootView.vue'),
    meta: { authorize: [ADMIN_ROLE] }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/', '/start', '/loggedout', '/errorlogin', '/doc'] //, '/dir'
  const authRequired = !(publicPages.includes(to.path))
  const authorize = to.meta.authorize

  // let's always ping
  await store.dispatch('auth/ping')
  const currentUser = AuthService.getCurrentUser()

  // console.warn('Authorize', authorize, authRequired)
  if (authorize && authRequired) {
    if (currentUser == null) {
      store.commit('auth/redirectAfterLogin', to.fullPath)
      next('/')
    } else if (currentUser !== null) {
      // console.warn('existing user', currentUser)

      const pass = AuthService.hasAnyRole(authorize)

      if (!pass) {
        // The user was authenticated, but did not have the correct role
        // Redirect to an error page
        next({ name: 'unauthorized' })
      } else {
        // This page did not require authentication
        next()
      }
    }
  } else {
    next()
  }
})

export default router
