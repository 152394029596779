import Vue from 'vue'

function trimArray (array, limit) {
  const arrLength = array.length
  if (arrLength > limit) {
    array.splice(0, arrLength - limit)
  }

  return array
}

function handleResp (userMsgs, data) {
  // console.warn('Got ', userMsgs, data)
  if (data.resp) {
    const respToMsg = userMsgs.find(obj => {
      return obj.stompid === data.resp
    })
    // Vue.set(respToMsg, 'resp', data)
    data.resp = respToMsg
  }
}

function setNotifStatus (stateObj, data) {
  const notif = stateObj.find(msg => msg.stompid === data.stompid)
  if (notif) {
    Vue.set(notif, 'proc', data.status)
  }
}

function receiveMsg (data, msgsObj, receivedMsgsObj, maxSize, callbacksObj) {
  handleResp(msgsObj, data)
  // data.trg = respToMsg
  receivedMsgsObj.push(data)
  receivedMsgsObj = trimArray(receivedMsgsObj, maxSize)
  if (data.resp) {
    const callback = callbacksObj[data.resp]
    if (callback) {
      callback(data)
      delete callbacksObj[data.resp]
    }
  }
}

function addMsg (data, msgsObj, maxSize, callbackObj) {
  const callback = data.callback
  // delete data.callback
  msgsObj.push(data)
  msgsObj = trimArray(msgsObj, maxSize)
  if (callback) {
    callbackObj[data.stompid] = callback
  }
}

export const stomp = {
  namespaced: true,
  state: {
    /** ############### Receive ############## */
    /** user notifications */
    receivedUserMsgs: [],
    receivedUserMsgsMaxSize: 10,
    userMessagesCallbacks: {},
    /** admin notifications */
    receivedAdminMsgs: [],
    receivedAdminMsgsMaxSize: 10,
    adminMessagesCallbacks: {},
    /** portal notifications */
    receivedPortalMsgs: [],
    receivedPortalMsgsMaxSize: 10,
    portalMessagesCallbacks: {},
    /** ############### Send ############## */
    /** user messages */
    userMsgs: [],
    userMsgsMaxSize: 10,
    /** admin messages */
    adminMsgs: [],
    adminMsgsMaxSize: 10,
    /** portal messages */
    portalMsgs: [],
    portalMsgsMaxSize: 10
  },
  getters: {
    userNotifs: state => {
      return state.receivedUserMsgs.filter(msg => msg.type === 'notif')
    },
    newUserNotifs: (state, getters) => {
      return getters.userNotifs.filter(notif => notif.proc !== 'done')
    },
    portalNotifs: state => {
      return state.receivedPortalMsgs.filter(msg => msg.type === 'notif')
    },
    adminNotifs: state => {
      return state.receivedAdminMsgs.filter(msg => msg.type === 'notif')
    }
  },
  mutations: {
    receiveUserMsg (state, data) {
      receiveMsg(data, state.userMsgs, state.receivedUserMsgs, state.receivedUserMsgsMaxSize, state.userMessagesCallbacks)
    },
    receiveAdminMsg (state, data) {
      receiveMsg(data, state.adminMsgs, state.receivedAdminMsgs, state.receivedAdminMsgsMaxSize, state.adminMessagesCallbacks)
    },
    receivePortalMsg (state, data) {
      receiveMsg(data, state.portalMsgs, state.receivedPortalMsgs, state.receivedPortalMsgsMaxSize, state.portalMessagesCallbacks)
    },
    addUserMsg (state, data) {
      addMsg(data, state.userMsgs, state.userMsgsMaxSize, state.userMessagesCallbacks)
    },
    addAdminMsg (state, data) {
      addMsg(data, state.adminMsgs, state.adminMsgsMaxSize, state.adminMessagesCallbacks)
    },
    addPortalMsg (state, data) {
      addMsg(data, state.portalMsgs, state.portalMsgsMaxSize, state.portalMessagesCallbacks)
    },
    setUserNotifStatus (state, data) {
      setNotifStatus(state.receivedUserMsgs, data)
    },
    setAdminNotifStatus (state, data) {
      setNotifStatus(state.receivedAdminMsgs, data)
    },
    setPortalNotifStatus (state, data) {
      setNotifStatus(state.receivedPortalMsgs, data)
    }
  },
  actions: {
  }
}
