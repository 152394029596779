<template>
  <footer id="layout-footer" class="mt-auto"><div class="cloned-hero-nav"></div>
    <div class="container">
      <div class="footer-row">
        <div class="footer-logo">
          <a href="https://www.fau.de" aria-label="Friedrich-Alexander Universität Erlangen-Nürnberg">
              <img src="../assets/icons/logo/fau2022.svg" height="65px" />
          </a>
        </div>
        <div class="footer-address">
        </div>
        <div class="footer-meta">
          <nav aria-label="Kontakt, Impressum und Zusatzinformationen">
            <ul id="footer-nav" class="menu" v-if="tecitems">
              <template v-for="item in tecitems">
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item" v-if="item.hidden === false" :key="item._id">
                  <router-link :to="item.to" v-if="item.local">{{ $t('footer.' + item.id + '.label') }}</router-link>
                  <a :href="item.url" v-else>{{ $t('footer.' + item.id + '.label') }}</a>
                </li>
              </template>
            </ul>
          </nav>
          <nav class="svg-socialmedia round hoverbg" aria-label="RRZE Links">
            <div>
              <ul>
                <li class="fau-rrze">
                  <a data-wpel-link="internal" itemprop="sameAs" href="https://rrze.fau.de" aria-label="RRZE">
                    <img src="../assets/icons/logo/rrze.svg" height="35px" alt="RRZE" />
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <div>
    </div>
  </footer>
</template>

<script>
import DefaultApiService from '@/services/defaultApi.service'
import { i18nMixin } from '@/mixins/i18n.mixin'
const defaultApiService = new DefaultApiService()

export default {
  name: 'TheFooter',
  i18nCompName: 'footer',
  i18n: {
    messages: {}
  },
  components: {},
  mixins: [i18nMixin],
  data () {
    return {
      tecitems: []
    }
  },
  methods: {
    loadFooterConfig () {
      defaultApiService.list('listFooter', {}).then((response) => {
        this.tecitems = response
      })
    }
  },
  beforeMount () {
    this.loadFooterConfig()
  }
}
</script>

<style scoped>
</style>
