import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import ApolloClient from 'apollo-client'
import { ApolloLink, concat } from 'apollo-link'
import VueApollo from 'vue-apollo'

const API_URL = process.env.VUE_APP_API_URL
const PUB_URL = process.env.VUE_APP_PUB_URL

const VUE_APP_GVZ_API_ENDPOINT = process.env.VUE_APP_GVZ_API_ENDPOINT

const API_GVZ_ENDPOINT = API_URL + VUE_APP_GVZ_API_ENDPOINT
const PUB_GVZ_ENDPOINT = PUB_URL + VUE_APP_GVZ_API_ENDPOINT

// start api client
const apiLink = createHttpLink({
  uri: API_GVZ_ENDPOINT,
  credentials: 'include' // 'same-origin' // include = send cookies with the request
})

const apiApolloClient = new ApolloClient({
  link: apiLink,
  cache: new InMemoryCache()
})

// start pub client
const pubLink = createHttpLink({
  uri: PUB_GVZ_ENDPOINT
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = sessionStorage.getItem('gvz-api-token') //
  console.warn('Using token', token)
  operation.setContext({
    headers: {
      'X-Api-Key': token || '' // later may be Bearer
    }
  })

  return forward(operation)
})

const pubApolloClient = new ApolloClient({
  link: concat(authMiddleware, pubLink),
  cache: new InMemoryCache()
})

// start apolloProvider
const apolloProvider = new VueApollo({
  clients: {
    apiApolloClient,
    pubApolloClient
  },
  defaultClient: apiApolloClient
})

// exports
export default apolloProvider
