import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import store from './store'
import router from './router'
import i18n from './i18n'
import VueCompositionAPI from '@vue/composition-api'
import axios from 'axios'
import stompService from '@/services/stomp.service'
import qs from 'qs'
import VueClipboard from 'vue-clipboard2'
import TheApp from '@/TheApp'
import VueApollo from 'vue-apollo'
import apolloProvider from '@/apolloConfig'

VueClipboard.config.autoSetContainer = true

Vue.use(VueClipboard)
Vue.use(VueCompositionAPI)
Vue.use(VueApollo)

Vue.prototype.$stompService = stompService

Vue.config.productionTip = false

// workaround for sync modifier, see also https://github.com/vuejs/vue/issues/6428#issuecomment-358327547
Vue.prototype.$emitCase = function (name, ...args) {
  const camelCaseName = name.toLowerCase().replace(/-(.)/g,
    (_, g) => g.toUpperCase())
  this.$emit(name, ...args)
  this.$emit(camelCaseName, ...args)
}

// configure default axios
axios.defaults.paramsSerializer = pars => qs.stringify(pars, { arrayFormat: 'repeat' })
axios.interceptors.response.use(
  // Return a successful response back to the calling service
  response => {
    store.commit('auth/resetSessionTimer')
    if (!store.state.auth.status.serverOnline) {
      store.commit('auth/serverOnline')
    }
    return response
  },
  // Return any error which is not due to authentication back to the calling service
  error => {
    if (error.response) {
      if (error.response.status !== 401) {
        store.commit('auth/resetSessionTimer')
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    } else {
      if (store.state.auth.status.serverOnline) {
        store.commit('auth/serverOffline')
      }
      console.log('network error')
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    console.log('unauthorized request')
    return store.dispatch('auth/createUnauthorizedRequest', error.config)
  }
)

// initialize Vue
new Vue({
  store,
  router,
  i18n,
  // inject apolloProvider here like vue-router or vuex
  apolloProvider,
  render: h => h(TheApp)
}).$mount('#app')
