import axios from 'axios'
import store from '@/store'
import router from '@/router'

const API_URL = process.env.VUE_APP_API_URL
const DEFAULT_API_ENDPOINT = process.env.VUE_APP_DEFAULT_API_ENDPOINT
const VUE_APP_HOME_URL = process.env.VUE_APP_HOME_URL
const VUE_APP_LOGOUT_URL = process.env.VUE_APP_LOGOUT_URL
const VUE_APP_AUTH_URL = process.env.VUE_APP_AUTH_URL

const receiveMessage = event => {
  // do we trust? might be different from what we originally opened
  const arr = window.location.href.split('/')
  const myOrigin = arr[0] + '//' + arr[2]
  if (event.origin !== myOrigin) {
    return
  }
  const { data } = event
  if (data === 'portalpopup') {
    window.removeEventListener('message', receiveMessage)
    store.dispatch('auth/ping')
  }
}

let windowObjectReference = null
let previousUrl = null

function popupCenter ({ url, windowName, w, h }) {
  const y = window.top.outerHeight / 2 + window.top.screenY - (h / 2)
  const x = window.top.outerWidth / 2 + window.top.screenX - (w / 2)
  return window.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`)
}

const openInWindow = (url, name) => {
  window.removeEventListener('message', receiveMessage)
  if (windowObjectReference === null || windowObjectReference.closed) {
    windowObjectReference = popupCenter({ url: url, title: name, w: 600, h: 700 })
  } else if (previousUrl !== url) {
    windowObjectReference = popupCenter({ url: url, title: name, w: 600, h: 700 })
    windowObjectReference.focus()
  } else {
    windowObjectReference.focus()
  }
  window.addEventListener('message', receiveMessage, false)
  previousUrl = url
}

class AuthService {
  getCurrentUser () {
    return store.state.auth.user
  }

  login () {
    store.commit('auth/inProgress', true)
    openInWindow(VUE_APP_HOME_URL, 'apiauth')
    // window.location = LOGIN_URL;
  }

  logout () {
    if (router.name !== 'home') {
      router.push({ name: 'home' })
    }
    store.commit('auth/inProgress', true)
    openInWindow(VUE_APP_LOGOUT_URL, 'apiauth')
  }

  hasAnyRole (roles) {
    let result = false
    const currentUser = this.getCurrentUser()

    if (currentUser && currentUser.roles) {
      // console.warn(currentUser.roles, 'vs', roles)
      roles.forEach(role => {
        if (currentUser.roles.includes(role)) {
          result = true
        }
      })
    }
    return result
  }

  ping () {
    return axios.get(API_URL + DEFAULT_API_ENDPOINT + 'ping', { withCredentials: true }).then(
      response => {
        return response.data
      }
    )
  }

  loginRedirect () {
    let loginUrl = VUE_APP_AUTH_URL
    if (loginUrl.endsWith('/')) {
      loginUrl = loginUrl.substring(0, loginUrl.length - 1)
    }

    let redUri = loginUrl
    let pubPath = VUE_APP_HOME_URL
    const uri = store.state.auth.redirectAfterLogin
    if (uri !== null && uri !== undefined && uri !== '/') {
      if (pubPath.endsWith('/') && uri.startsWith('/')) {
        pubPath = pubPath.substring(0, pubPath.length - 1)
      }
      pubPath = pubPath + uri
    }
    redUri += ('?uri=' + pubPath)

    window.location = redUri
  }

  logoutRedirect () {
    window.location = VUE_APP_LOGOUT_URL
  }

  replay (config) {
    return axios.request(config)
  }
}

export default new AuthService()
