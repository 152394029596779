<template>
  <b-nav-item @click="ping" :title="autoSignoutText">
    <div v-if="timer">
      <b-icon-clock-history :class="{ 'text-warning': warn }"/>
      {{ countdownShort }}
    </div>
    <div v-else>
      <b-icon-cloud :title="$t('ping.label')"/>
    </div>
    <b-modal v-model="sessionTimedOut" ok-only :ok-title="$t('dismiss.label')" size="sm" button-size="sm" ok-variant="secondary" centered>
      <template #modal-header="{ }">
        {{ $t('session.timeout.title') }}
      </template>
      <div class="text-center">
        <b-icon-door-closed scale="2"/>
      </div>
    </b-modal>
  </b-nav-item>
</template>

<script>
import { authMixin } from '@/mixins/auth.mixin'
import { notificationsMixin } from '@/mixins/notifications.mixin'

export default {
  name: 'TheSessionTimer',
  mixins: [authMixin, notificationsMixin],
  data () {
    return {
      timeout: null,
      sessionTimedOut: false
    }
  },
  computed: {
    timer () {
      return this.$store.state.auth.session.timer
    },
    warn () {
      if (this.secondsLeft < 61) {
        return true
      }
      return false
    },
    secondsLeft () {
      return this.$store.state.auth.session.secondsLeft
    },
    countdown () {
      if (this.secondsLeft < 61) {
        return this.secondsLeft + ' ' + this.$tc('second.label', this.secondsLeft)
      } else {
        const minutes = Math.floor(this.secondsLeft / 60) + 1
        return minutes + ' ' + this.$tc('minute.label', minutes)
      }
    },
    countdownLong () {
      if (this.secondsLeft < 61) {
        // return this.secondsLeft + ' ' + this.$tc('second.label', this.secondsLeft)
        return this.$t('less.than.one.minute.label')
      }
      const minutes = Math.floor(this.secondsLeft / 60) + 1
      return minutes + ' ' + this.$tc('minute.label', minutes)
    },
    countdownShort () {
      if (this.secondsLeft < 61) {
        return this.secondsLeft + '' + this.$t('second.unit')
      }
      const minutes = Math.floor(this.secondsLeft / 60) + 1
      return minutes + '' + this.$t('minute.unit')
    },
    autoSignoutText () {
      return this.$t('auto.signout.text', { time: this.countdownLong }) + (this.loginTime ? ' ' + this.loggedSince : '')
    },
    loginTime () {
      return this.$store.state.auth.session.loginTime
    },
    loggedSince () {
      return this.$t('auto.loginTime.text', { since: new Date(this.loginTime).toLocaleTimeString('de-DE') })
    }
  },
  watch: {
    timer: {
      immediate: true,
      handler: 'onTimer'
    }
  },
  methods: {
    onTimer (timer) {
      if (timer) {
        // start session timer
        this.update()
      } else {
        // stop session timer
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
      }
    },
    update () {
      this.$store.commit('auth/updateSessionTimer')
      let timeoutMs = 60000
      if (this.secondsLeft <= 0) {
        this.sessionTimedOut = true
        this.$store.commit('auth/stopSessionTimer')
        return
      } else if (this.secondsLeft < 70) {
        timeoutMs = 1000
      } else {
        const timeoutToNextMinute = Math.floor(this.secondsLeft % 60) * 1000
        if (timeoutToNextMinute !== 0) {
          timeoutMs = timeoutToNextMinute + 1000
        }
        // const foo = Math.floor(this.secondsLeft / 60)
        // console.log('next in ' + timeoutMs + ' (min: ' + foo + ', left: ' + this.secondsLeft + ')')
      }
      this.sessionTimedOut = false
      this.timeout = setTimeout(() => this.update(), timeoutMs)
    },
    reset () {
      this.$store.commit('auth/resetSessionTimer')
    },
    ping () {
      const requestStartedAt = new Date().getTime()
      this.$store.dispatch('auth/ping').then(
        () => this.makeToast(this.$i18n.t('ping.result.text', { time: new Date().getTime() - requestStartedAt }), this.$i18n.t('result.success.title'), 'success')
      ).catch(
        error => this.makeToast(this.$i18n.t('error.text', { status: 'na', message: error.message, id: 'ping' }), this.$i18n.t('result.error.title'), 'danger')
      )
    }
  }
}
</script>

<style scoped>

</style>
