import AuthService from '@/services/auth.service'
import router from '@/router'
import _ from 'lodash'

const initialState = {
  status: {
    clientOnline: true,
    serverVersion: '0',
    serverNode: null,
    serverNodes: [],
    serverOnline: true,
    loggedIn: false,
    switched: false,
    inProgress: false
  },
  redirectAfterLogin: null,
  triggerLogin: false,
  unauthorized: {
    inProgress: false,
    modal: false,
    confirmed: false,
    deferreds: []
  },
  user: null,
  session: {
    timer: false,
    accessed: 0,
    secondsLeft: 0,
    loginTime: null
  },
  switchedUserOriginalUsername: null
}

export const auth = {
  namespaced: true,
  state: initialState,
  getters: {
    serverVersion: (state) => {
      return state.status.serverVersion
    },
    serverNode: (state) => {
      return state.status.serverNode
    },
    serverNodes: (state) => {
      return state.status.serverNodes
    },
    switched: (state) => {
      return state.status.switched
    },
    switchedUserOriginalUsername: (state) => {
      return state.switchedUserOriginalUsername
    }
  },
  actions: {
    ping ({ commit, state }) {
      if (state.status.inProgress) {
        commit('inProgress', false)
      }
      if (typeof window.navigator.onLine === 'undefined' || window.navigator.onLine) {
        // assume online if browser doesn't support it
        if (!state.status.clientOnline) {
          commit('clientOnline')
        }
        return AuthService.ping().then(
          pong => {
            if (!state.status.serverOnline || state.status.serverVersion !== pong.version || state.status.serverNode !== pong.node || state.status.serverNodes !== pong.nodes) {
              commit('serverOnline', pong)
            }
            if (typeof pong.user !== 'undefined') {
              commit('loginSuccess', pong.user)
              if (pong.user.interval) {
                commit('startSessionTimer')
              }
              if (state.status.switched !== pong.switched && state.switchedUserOriginalUsername !== pong.switchedUserOriginalUsername) {
                commit('switch', pong)
              }
              while (state.unauthorized.confirmed && !_.isEmpty(state.unauthorized.deferreds)) {
                const deferred = _.head(state.unauthorized.deferreds)
                AuthService.replay(deferred.config).then(
                  response => {
                    deferred.resolve(response)
                  },
                  error => {
                    deferred.reject(error)
                  }
                )
                commit('processedDeferredUnauthorizedRequest')
              }
              commit('processedUnauthorizedRequest')
              if (state.redirectAfterLogin) {
                const fullPath = state.redirectAfterLogin
                commit('clearRedirectAfterLogin')
                router.push(fullPath)
              }
            } else {
              if (state.status.loggedIn || state.user) {
                commit('logoutSuccess')
                commit('stopSessionTimer')
              }
            }
            return Promise.resolve(pong.user)
          },
          () => {
            if (state.status.serverOnline) {
              commit('serverOffline')
            }
            return Promise.resolve()
          }
        )
      } else {
        if (state.status.clientOnline) {
          commit('clientOffline')
        }
        return Promise.reject(Error('client offline'))
      }
    },
    createUnauthorizedRequest ({ commit }, config) {
      // TODO support multiple unauthorized requests -> deferred as list
      const deferred = {
        config: config
      }
      const p = new Promise((resolve, reject) => {
        deferred.resolve = resolve
        deferred.reject = reject
      })
      commit('unauthorizedRequest', deferred)
      return p
    },
    confirmUnauthorizedRequest ({ commit }) {
      commit('confirmedUnauthorizedRequest')
      AuthService.loginRedirect()
    },
    declineUnauthorizedRequest ({ commit, state }) {
      while (!_.isEmpty(state.unauthorized.deferreds)) {
        const deferred = _.head(state.unauthorized.deferreds)
        deferred.reject()
        commit('processedDeferredUnauthorizedRequest')
      }
      commit('declinedUnauthorizedRequest')
    }
  },
  mutations: {
    clientOnline (state) {
      state.status.clientOnline = true
    },
    clientOffline (state) {
      state.status.clientOnline = false
    },
    serverOnline (state, payload) {
      if (payload) {
        state.status.serverVersion = payload.version
        state.status.serverNode = payload.node
        state.status.serverNodes = payload.nodes
      }
      state.status.serverOnline = true
    },
    serverOffline (state) {
      state.status.serverOnline = false
    },
    redirectAfterLogin (state, fullPath) {
      state.redirectAfterLogin = fullPath
    },
    clearRedirectAfterLogin (state) {
      state.redirectAfterLogin = null
    },
    triggerLogin (state, newValue) {
      state.triggerLogin = newValue
    },
    inProgress (state, value) {
      if (value) {
        state.status.inProgress = true
      } else {
        state.status.inProgress = false
      }
    },
    loginSuccess (state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    logoutSuccess (state) {
      state.status.loggedIn = false
      state.user = null
    },
    switch (state, payload) {
      state.status.switched = payload.switched
      state.switchedUserOriginalUsername = payload.switchedUserOriginalUsername
    },
    unauthorizedRequest (state, deferred) {
      state.unauthorized.inProgress = true
      state.unauthorized.modal = true
      state.unauthorized.confirmed = false
      state.unauthorized.deferreds = _.concat(state.unauthorized.deferreds, deferred)
    },
    confirmedUnauthorizedRequest (state) {
      state.unauthorized.modal = false
      state.unauthorized.confirmed = true
    },
    declinedUnauthorizedRequest (state) {
      state.unauthorized.inProgress = false
      state.unauthorized.modal = false
      state.unauthorized.confirmed = false
      state.unauthorized.deferreds = []
    },
    processedDeferredUnauthorizedRequest (state) {
      if (!_.isEmpty(state.unauthorized.deferreds)) {
        state.unauthorized.deferreds = _.tail(state.unauthorized.deferreds)
      }
    },
    processedUnauthorizedRequest (state) {
      state.unauthorized.inProgress = false
      state.unauthorized.confirmed = false
      state.unauthorized.deferreds = []
    },
    startSessionTimer (state) {
      const initialLockSkew = 5
      state.session.accessed = Math.floor(Date.now() / 1000) - initialLockSkew
      state.session.secondsLeft = state.user.interval - initialLockSkew
      state.session.timer = true
      state.session.loginTime = state.user.loginTime
    },
    stopSessionTimer (state) {
      if (state.session.timer) {
        state.session.timer = false
        state.session.accessed = 0
        state.session.secondsLeft = 0
        state.session.loginTime = null
      }
    },
    resetSessionTimer (state) {
      if (state.session.timer) {
        state.session.accessed = Math.floor(Date.now() / 1000) - 1 // clock skew
        state.session.secondsLeft = state.user.interval - 1 // clock skew
      }
    },
    updateSessionTimer (state) {
      if (state.session.timer) {
        state.session.secondsLeft = state.user.interval - (Math.floor(Date.now() / 1000) - state.session.accessed)
      }
    }
  }
}
