<template>
  <div v-if="!popup && (messagesAvailable)" class="d-flex flex-column min-vh-100">
    <TheHeader/>
    <main id="main-content">
      <router-view/>
    </main>
    <TheFooter />
    <b-modal v-model="backdropApp" ok-only :ok-title="$t('dismiss.label')" size="sm" button-size="sm" ok-variant="secondary" centered>
      <template #modal-header="{ }">
        {{ $t('auth.inprogress.title') }}
      </template>
      <div class="text-center">
        <b-spinner :label="$t('auth.inprogress.title')" variant="secondary" type="grow"></b-spinner>
      </div>
    </b-modal>
    <b-alert fade variant="warning" class="position-fixed fixed-bottom m-0 rounded-0" style="z-index: 2000;" show v-if="offline">{{ $t('offline.label') }}</b-alert>
    <b-alert fade variant="danger" class="position-fixed fixed-bottom m-0 rounded-0" style="z-index: 2000;" show v-else-if="serverUnavailable">{{ $t('no.connection.label') }}</b-alert>
  </div>
</template>

<script>
import store from './store'
import TheHeader from '@/components/TheHeader'
import TheFooter from '@/components/TheFooter'
import { loadLanguageAsync } from '@/i18n'
import { authMixin } from '@/mixins/auth.mixin'
import AuthService from '@/services/auth.service'

const onOffListener = () => {
  store.dispatch('auth/ping')
}

const preventNav = (event) => {
  if (store.state.pendingChanges.length < 1) {
    return
  }
  event.preventDefault()
  // Chrome requires returnValue to be set.
  event.returnValue = ''
}

export default {
  name: 'TheApp',
  mixins: [authMixin],
  components: {
    TheFooter,
    TheHeader
  },
  data: function () {
    return {
      popup: false,
      backdropApp: false,
      sessionTimeout: false
    }
  },
  computed: {
    theme () {
      return this.$store.state.prefs.theme
    },
    offline () {
      return !this.$store.state.auth.status.clientOnline
    },
    serverUnavailable () {
      return !this.$store.state.auth.status.serverOnline
    },
    unauthorizedModal () {
      return this.$store.state.auth.unauthorized.modal
    },
    messagesAvailable () {
      if ('$i18n' in this) {
        return Object.keys(this.$i18n.getLocaleMessage(this.$i18n.locale)).length !== 0
      } else {
        return false
      }
    },
    portalNotifs () {
      return this.$store.getters['stomp/portalNotifs']
    },
    adminNotifs () {
      return this.$store.getters['stomp/adminNotifs']
    },
    userNotifs () {
      return this.$store.getters['stomp/userNotifs']
    }
  },
  watch: {
    currentUser (newUser, oldUser) {
      // console.warn('[Web Socket] ', 'Current User', newUser)
      // console.warn('[Web Socket] ', 'Old User', oldUser)
      this.wsUserChanged(newUser, oldUser)
    },
    unauthorizedModal (newValue) {
      if (newValue === true) {
        this.showMsgBoxLogin()
      }
    },
    authInProgress (newValue) {
      this.backdropApp = newValue
    },
    backdropApp (newValue) {
      if (newValue === false) {
        this.$store.dispatch('auth/ping')
      }
    },
    theme: {
      immediate: true,
      handler: 'changeTheme'
    },
    portalNotifs (n, o) {
      const notif = this.portalNotifs.pop()
      const payload = { message: notif.txt, title: 'Portal Info' }
      if (notif.st !== 0) {
        payload.variant = 'danger'
        payload.title = 'Portal Error'
        payload.message = notif.error
      }
      this.toaster(payload)
    },
    adminNotifs (n, o) {
      const notif = this.adminNotifs.pop()
      const payload = { message: notif.txt, title: 'Admin Info' }
      if (notif.st !== 0) {
        payload.variant = 'danger'
        payload.title = 'Admin Error'
        payload.message = notif.error
      }
      this.toaster(payload)
    },
    userNotifs (n, o) {
      const notif = this.userNotifs.pop()
      const payload = { message: notif.txt, title: 'User Info' }
      if (notif.st !== 0) {
        payload.variant = 'danger'
        payload.title = 'User Error'
        payload.message = notif.error
      }

      if (this.currentUser !== null) {
        if (notif.op === 'logout' && notif.usr === this.currentUser.username) {
          console.log('Web Socket logout', this.currentUser.username)
          AuthService.logoutRedirect()
        }
      }

      this.toaster(payload)
    }
  },
  created () {
    if (window.opener) {
      this.popup = true
      window.opener.postMessage('portalpopup')
      window.close()
    } else {
      window.addEventListener('online', onOffListener)
      window.addEventListener('offline', onOffListener)
      window.addEventListener('beforeunload', preventNav)
      this.$store.dispatch('auth/ping')
      this.changeLocale(this.$store.state.prefs.lang)
    }
  },
  destroyed () {
    window.removeEventListener('online', onOffListener)
    window.removeEventListener('offline', onOffListener)
    window.removeEventListener('beforeunload', preventNav)
    this.$stompService.disconnect()
  },
  methods: {
    changeTheme (theme) {
      if (['auto', 'dark'].indexOf(theme) >= 0) {
        document.body.setAttribute('data-theme', theme)
      } else {
        document.body.removeAttribute('data-theme')
      }
    },
    changeLocale (lang) {
      this.$store.commit('prefs/lang', lang)
      loadLanguageAsync(lang)
    },
    showMsgBoxLogin () {
      this.$bvModal.msgBoxConfirm(this.$i18n.t('unauthorized.text'), {
        title: this.$i18n.t('unauthorized.title'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: this.$i18n.t('signin.label'),
        cancelTitle: this.$i18n.t('cancel.label'),
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
        .then(value => {
          if (value) {
            this.$store.state.auth.redirectAfterLogin = this.$router.currentRoute.fullPath
            this.$store.dispatch('auth/confirmUnauthorizedRequest')
          } else {
            this.$store.dispatch('auth/declineUnauthorizedRequest')
          }
        })
        .catch(err => {
          // An error occurred
          console.log(err)
        })
    },
    toaster (props) {
      this.$bvToast.toast(props.message, { variant: props.variant || 'primary', title: props.title || 'Info' })
    },
    /** start stomp (web socket) methods */
    wsUserMsgHandler (jsonMsg) {
      this.$store.commit('stomp/receiveUserMsg', jsonMsg)
    },
    wsPortalMsgHandler (jsonMsg) {
      // console.debug('[Web Socket]', 'Vue Portal handler got message:', jsonMsg)
      this.$store.commit('stomp/receivePortalMsg', jsonMsg)
    },
    wsAdminMsgHandler (jsonMsg) {
      // console.debug('[Web Socket]', 'Vue Admin handler got message:', jsonMsg)
      this.$store.commit('stomp/receiveAdminMsg', jsonMsg)
    },
    wsErrorHandler (error) {
      console.warn('[Web Socket]', 'Connection error')
      console.error(error)
      setTimeout(this.wsConnect, 3000)
      console.warn('STOMP: Reconnecting in 3 seconds')
    },
    async wsUserChanged (newUser, oldUser) {
      try {
        if (!newUser && this.$stompService.isConnected()) {
          this.$stompService.disconnect()
          // console.debug('[Web Socket] ', 'Disconnecting stompService - no user')
          return
        }

        let userChanged = false
        if (!oldUser && newUser.username) {
          userChanged = true
        } else {
          // userChanged = true // to reconnect and update bearer header
          if (newUser) {
            userChanged = newUser.username !== oldUser.username
          }
        }

        // console.debug('[Web Socket] ', 'User changed', userChanged)
        if (userChanged && this.$stompService.isConnected()) {
          // console.debug('[Web Socket] ', 'User changed => disconnecting StompService')
          this.$stompService.disconnect()
          // console.debug('[Web Socket] ', 'User changed => reinitializing StompService')
          this.$stompService.init()
        }

        if (userChanged) {
          this.wsConnect(newUser)
        }
      } catch (e) {
        console.error('Error connecting to stomp server', e)
      }
    },
    wsConnect (user = null) {
      if (!user) {
        user = this.currentUser
      }
      if (user) {
        // console.debug('[Web Socket] ', 'Connecting user to topics', user)
        const topicConfig = {
          errorHandler: this.wsErrorHandler,
          userHandler: this.wsUserMsgHandler,
          portalHandler: this.wsPortalMsgHandler
        }
        const ADMIN_ROLE = process.env.VUE_APP_ADMIN_ROLE
        if (user.roles.includes(ADMIN_ROLE)) {
          topicConfig.adminHandler = this.wsAdminMsgHandler
        }

        // console.debug('[Web Socket] ', 'Connecting user to topics', 'username:', user.username, 'headers:', topicConfig.headers)
        this.$stompService.connectUser(topicConfig)
      }
    }

    /** end stomp (web socket) methods */
  }
}
</script>
