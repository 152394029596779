
export const notificationsMixin = {
  computed: {
  },
  methods: {
    makeToast (message, title, variant, delay) {
      let autoHideDelay = 5000
      if (delay) {
        autoHideDelay = delay
      }

      this.$bvToast.toast(message, {
        title: title,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        autoHideDelay: autoHideDelay
      })
    },
    makeHtmlToast (message, title, variant, delay) {
      let autoHideDelay = 5000
      if (delay) {
        autoHideDelay = delay
      }

      const virtualElement = this.$createElement('span', {
        domProps: {
          innerHTML: message
        }
        /*
        class: ['alert', 'alert-primary'],
        // Normal HTML attributes
        attrs: {
          role: 'alert'
        }
        */
      })

      this.$bvToast.toast([virtualElement], {
        title: title,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        autoHideDelay: autoHideDelay
      })
    }
  }
}
