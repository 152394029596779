import _ from 'lodash'

const LS_PREFS_KEY_PREFIX = 'editor_prefs_'
const EMPTY_PREFS_STATE = {
  editorFontSize: 1.0
}

function initPrefsByEditor (editorId) {
  let initialState = JSON.parse(localStorage.getItem(LS_PREFS_KEY_PREFIX + editorId))
  if (initialState === null) {
    initialState = {}
  }
  initialState = _.merge(_.cloneDeep(EMPTY_PREFS_STATE), initialState)
  localStorage.setItem(LS_PREFS_KEY_PREFIX + editorId, JSON.stringify(initialState))
  return initialState
}

export const editor = {
  namespaced: true,
  state: {
    activeTab: null,
    // tabs: [],
    editors: [],
    jobs: [],
    prefs: []
  },
  getters: {
    getEditorById: (state) => (id) => {
      return state.editors.find(e => e.id === id)
    },
    getJobsByEditor: (state) => (editorId) => {
      return state.jobs.filter(j => j.editorId === editorId)
    },
    getPrefsByEditor: (state) => (editorId) => {
      let prefs = state.prefs.find(e => e.editorId === editorId)
      if (prefs === undefined) {
        prefs = initPrefsByEditor(editorId)
      }
      return prefs
    }
  },
  mutations: {
    save (state, editor) {
      const editorIdx = state.editors.findIndex(e => e.id === editor.id)
      if (editorIdx >= 0) {
        state.editors.splice(editorIdx, 1, editor)
      } else {
        state.editors.push(editor)
      }
    },
    addJobs (state, jobs) {
      if (Array.isArray(jobs)) {
        const myJobs = _.cloneDeep(state.jobs)
        jobs.forEach(j => myJobs.push(j))
        state.jobs = myJobs
      } else {
        state.jobs.push(jobs)
      }
    },
    setJobs (state, jobs) {
      state.jobs = jobs
    },
    editorFontSize (state, payload) {
      let pref = state.prefs.find(e => e.editorId === payload.editorId)
      if (pref === undefined) {
        pref = initPrefsByEditor(payload.editorId)
        state.prefs.push(pref)
      }
      if (pref.editorFontSize !== payload.editorFontSize) {
        pref.editorFontSize = payload.editorFontSize
        localStorage.setItem(LS_PREFS_KEY_PREFIX + payload.editorId, JSON.stringify(pref))
      }
    }
  },
  actions: {
    processJobsForEditor ({ commit, state }, editorId) {
      const myJobs = _.cloneDeep(state.jobs)
      const editorJobs = myJobs.filter(j => j.editorId === editorId)
      if (editorJobs.length > 0) {
        editorJobs.forEach(editorJob => {
          const jobIdX = myJobs.findIndex(j => j === editorJob)
          if (jobIdX >= 0) {
            myJobs.splice(jobIdX, 1)
          }
        })
        commit('setJobs', myJobs)
      }
      return editorJobs
    }
  }
}
